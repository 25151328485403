import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import {
  GetStudentsForMatchMoodleModulStudentsQuery,
  ResultType,
  useGetStudentsForMatchMoodleModulStudentsLazyQuery,
} from "graphqlBase/Students/__generated__/getStudentsForMatchMoodleModulStudents";
import {
  useAddMatchMoodleModulStudentsMutation,
  AddMatchMoodleModulStudentsMutationVariables,
} from "graphqlBase/MatchMoodleModulStudent/__generated__/addMatchMoodleModulStudents";
import { useGetMatchMoodleModulStudentsQuery } from "graphqlBase/MatchMoodleModulStudent/__generated__/getMatchMoodleModulStudents";
import {
  SortEnumType,
  StudentFilterInput,
  StudentSortInput,
  MatchMoodleModulStudentCreateType,
} from "graphqlBase/types";
import { default as React, useCallback, useEffect, useState } from "react";
import Modal from "components/lbc-toolkit/molecules/Modal";
import AddStundentsToPruefung from "./AddStundentsToPruefungTable";

type Student = ResultType<GetStudentsForMatchMoodleModulStudentsQuery["students"]>;

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Student,
  StudentFilterInput,
  StudentSortInput,
  SortEnumType
>();

interface AddStudentsToMoodleModuleProps {
  pruefungId: number;
  addStundents: (studentIds: number[]) => Promise<void>;
  studentsInPruefung: number[];
}

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
      },
      studentAddButton: {
        cursor: "pointer",
        height: "40px",
        alignItems: "center",
        appearance: "none",
        backgroundColor: theme.colors.christineRed,
        borderStyle: "none",
        // borderRadius: "4px",
        color: theme.colors.tWhite,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // width: "217px",
        marginBottom: "10px",
      },
      title: {
        textTransform: "uppercase",
        boxSizing: "content-box",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "26px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        letterSpacing: "0.46px",
      },
    }),
  { name: "AddStudentsToMoodleModule" }
);

const AddStudentsToMoodleModule: React.FC<AddStudentsToMoodleModuleProps> = ({
  studentsInPruefung,
  pruefungId,
  addStundents,
}) => {
  const classes = useStyles({});

  const [edit, setEdit] = useState(false);
  const [studentIdsToAdd, setStudentIdsToAdd] = useState<number[]>([]);
  const [saveNewStudents] = useAddMatchMoodleModulStudentsMutation();

  const reset = () => {
    setEdit(false);
  };

  const addStudentToMoodleModul = () => {
    if (!studentIdsToAdd.length) {
      reset();
      return;
    }
    const matchMoodleModulStudents = studentIdsToAdd.map((studentId) => studentId);

    addStundents(matchMoodleModulStudents).then(() => {
      setEdit(false);
    });
  };

  const Table = useCallback(
    () =>
      edit ? (
        <AddStundentsToPruefung
          pruefungId={pruefungId}
          studentsInPruefung={studentsInPruefung}
          setStudentIdsToAdd={setStudentIdsToAdd}
        />
      ) : (
        <div />
      ),
    [setStudentIdsToAdd, pruefungId, studentsInPruefung, edit]
  );

  return (
    <>
      <Modal
        open={!!edit}
        onClose={reset}
        onCancel={reset}
        width={"80vh"}
        height={"80vh"}
        primaryCancel
        onConfirm={addStudentToMoodleModul}
        confirmLabel={"Auswahl hinzufügen"}
        title={"Studenten zu Prüfung hinzufügen"}
      >
        {edit ? Table() : <div />}
      </Modal>
      <div className={classes.root}>
        <button className={classes.studentAddButton} onClick={() => setEdit(true)}>
          <span className={classes.title}>Studenten zu Prüfung hinzufügen</span>
        </button>
      </div>
    </>
  );
};
export default AddStudentsToMoodleModule;
