import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Props from "./index.d";
import { useGetMatchStudiengangStudentsOnStudentQuery } from "../../../../graphqlBase/matchStudiengangStudents/__generated__/getMatchStudiengangStudentsOnStudent";
import {
  MatchStudiengangStudentUpdateForm,
  MatchStudiengangStudentCreateOnUpdateDataMiddleWareProps,
} from "../../../../raft/MatchStudiengangStudentForm";
import Typography from "@material-ui/core/Typography";
import { useGetMatchStudentSemestersLazyQuery } from "graphqlBase/MatchStudentSemesters/__generated__/getMatchStudentSemesters";

import DefaultInput from "../../../atomics/DefaultInput";

interface MatchStudiengangStudentProps {
  studentId: number;
  readonly: boolean;
  title: string;
}

interface TextFieldComponentProps {
  status: string;
  title: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: "100%",
      overflow: "hidden",
    },
  })
);

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({ status, title }) => {
  const classes = useStyles(status);

  return <DefaultInput className={classes.input} value={status} disabled={true} label={title} defaultValue="" />;
};

const MatchStudiengangStudent: React.FC<MatchStudiengangStudentProps> = ({ studentId, readonly, title }) => {
  const { data, refetch } = useGetMatchStudiengangStudentsOnStudentQuery({ variables: { id: studentId } });
  const matchStudiengangStudent = data?.matchStudiengangStudents[0];
  const [getMatchStudentSemestersQuery] = useGetMatchStudentSemestersLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      filterInput: { studentId: { eq: studentId } },
    },
  });

  if (!matchStudiengangStudent?.id) return null;
  if (readonly) {
    return <TextFieldComponent title={title} status={matchStudiengangStudent?.studiumArt?.studiumArtName ?? ""} />;
  }
  return (
    <MatchStudiengangStudentUpdateForm
      id={matchStudiengangStudent?.id}
      afterUpdate={() => {
        refetch ? refetch() : () => {};
        getMatchStudentSemestersQuery();
      }}
    />
  );
};

const StudiumArt: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    title,
    disabled,
    submit,
    readonly,
    //@ts-ignore
    update,
  } = props;

  const classes = useStyles(props);
  useEffect(() => {
    if (submit?.some(({ state }) => state)) {
    }
  }, [submit]);
  if (!state) return null;
  return <MatchStudiengangStudent title={title} studentId={state} readonly={readonly ?? false} />;
};
export default StudiumArt;
