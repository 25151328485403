import { QqlFormGenConfig } from "@ml-pa/react-apollo-form-tool/types"

const config: QqlFormGenConfig = {
  clientPath: "src/apollo/client",
  graphpQqlEndPoint: "http://localhost:7000/graphql/",
  clientSchema: "./src/graphqlBase/client/clientSchema.graphql",
  raftGqlSchemas: "./src/**/*raftExt.graphql",
  localConfigurator: true,
  afterChange: "src/lib/formToolHelpers/testAfter.ts",
  localizationPath: "src/translations",
  languages: ["de", "en"],
  useDotNet2_1: false,
  out: "src/raft",
  elements: [
    "src/components/formElements/forms",
    "src/components/formElements/fieldSets",
    "src/components/formElements/fields",
  ],
  forms: [
    {
      entity: "bewerber",
      operations: {
        create: {
          params: {},
        },
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "student",
      operations: {
        create: {
          params: {
            ausbildungsArtId: "number",
          },
        },
        update: {
          params: {
            ausbildungsArtId: "number",
            id: "number",
          },
        },
      },
    },
    {
      entity: "anmeldung",
      operations: {
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "modul",
      operations: {
        create: {
          params: {
            ausbildungsArtId: "number",
          },
        },
        update: {
          params: {
            ausbildungsArtId: "number",
            id: "number",
          },
        },
      },
    },
    {
      entity: "matchStudiengangStudent",
      operations: {
        create: {
          params: {},
        },
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "studiengang",
      operations: {
        create: {
          params: {},
        },
        update: {
          params: {
            ausbildungsArtId: "number",
            id: "number",
          },
        },
      },
    },
    {
      entity: "moodleModul",
      operations: {
        create: {
          params: {},
        },
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "matchModulStudent",
      operations: {
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "pruefungsergebni",
      operations: {
        create: {
          params: {
            studentId: "number",
          },
        },
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "modultermin",
      operations: {
        create: {
          params: {
            moodleModulId: "number",
          },
        },
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
    {
      entity: "pruefung",
      operations: {
        create: {
          params: {
            modulId: "number",
            moodleModulId: "number",
            zeitraumId: "number",
            pruefungsdauer: "number",
          },
        },
        update: {
          params: {
            id: "number",
          },
        },
      },
    },
  ],
}
export default config
