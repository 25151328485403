import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import { SelectBooleanFilter } from "components/templates/table-factory/Table/Filters";
import {
  useGetPruefungsdatenLazyQuery,
  ResultType,
  GetPruefungsdatenQuery,
} from "graphqlBase/Pruefung/__generated__/getPruefungsdaten";
import { PruefungFilterInput, PruefungSortInput, SortEnumType } from "graphqlBase/types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatDateTime } from "translations/formatter";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em", paddingLeft: "28px", paddingRight: "33px" },
    }),
  { name: `StudentModul-StudienVerlauf` }
);

type ModulPruefung = ResultType<GetPruefungsdatenQuery["pruefungs"]>;

interface MoodleModulPruefungenListProps {
  // moodleModulId: number;
}

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  ModulPruefung,
  PruefungFilterInput,
  PruefungSortInput,
  SortEnumType
>();

const Pruefungsliste: React.FC<MoodleModulPruefungenListProps> = () => {
  const variables = {
    order: [{ datumUhrzeitVon: "ASC" as SortEnumType.Desc }],
  };
  const classes = useStyles();
  const [query, { data, refetch }] = useGetPruefungsdatenLazyQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });
  const history = useHistory();
  const handleEdit = (value: number) => {
    history.push(`pruefungen/pruefungsergebnisse/${value}`);
  };
  const ActionCell = actionsCellFactory({
    onEdit: handleEdit,
    onDelete: {
      entity: "pruefung",
      afterDelete: () => {
        if (refetch)
          refetch().catch(() => {
            //
          });
      },
    },
  });
  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Modul",
        accessor: (row) => row.moodleModul?.modul?.modulName ?? "",
        id: "Modul",
        remoteOrder: ({ order, sort }) => ({ datumUhrzeitVon: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { moodleModul: { modul: { modulName: { contains: filterValue } } } }),
        }),
      },
      {
        Header: "Semester",
        accessor: (row) => row.moodleModul?.zeitraum?.zeitraumName,
        id: "Semester",
        remoteOrder: ({ order, sort }) => ({ datumUhrzeitVon: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { moodleModul: { zeitraum: { zeitraumName: { contains: filterValue } } } }),
        }),
      },
      {
        Header: "Prüfungstyp",
        accessor: (row) => (row.pruefungTyp?.pruefungTypName ? row.pruefungTyp?.pruefungTypName : ""),
        id: "pruefungstyp",
        remoteOrder: ({ order, sort }) => ({ pruefungsversuch: sort }),
      },
      {
        Header: "Prüfungsnummer",
        accessor: (row) => (row.pruefungsversuch ? `Prüfungsnummer ${row.pruefungsversuch}` : ""),
        id: "pruefungsversuch",
        remoteOrder: ({ order, sort }) => ({ pruefungsversuch: sort }),
      },
      {
        Header: "Datum",
        accessor: (row) => (row.datumUhrzeitVon ? formatDateTime(new Date(row.datumUhrzeitVon)) : ""),
        id: "Datum",
        remoteOrder: ({ order, sort }) => ({ datumUhrzeitVon: sort }),
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        Cell: ActionCell,
        width: 60,
      },
    ],
    [refetch]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: data?.pruefungs ?? [],
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "MoodleModulPruefungenList",
    }
  );

  return (
    <div className={classes.root}>
      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default Pruefungsliste;
