import { Button } from "@lbc-toolkit/button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { prepareServerSideTable } from "components/templates/table-factory";
import {
  GetPruefungsergebniseQuery,
  ResultType,
  useGetPruefungsergebniseLazyQuery,
} from "graphqlBase/Pruefungsergebnis/__generated__/getPruefungsergebnise";
import { useCreatePruefungsergebnisMutation } from "graphqlBase/Pruefungsergebnis/__generated__/createPruefungsergebnis";
import { PruefungsergebniFilterInput, PruefungsergebniSortInput, SortEnumType } from "graphqlBase/types";
import React, { useEffect, useState, useCallback } from "react";
import {
  useMakeDecimalFieldInputCell,
  useMakeForeignKeySelctor,
  useMakeInputCell,
  useMakeNoteCell,
  useMakePunkteCell,
} from "./InputCells";
import AddStundentsToPruefung from "./AddStudentsToPruefung";
import actionsCellFactory from "components/templates/table-factory/Table/Cells/actionsCellFactory";
import { Typography } from "@material-ui/core";
import translations from "translations";
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      root: { width: "100%", marginTop: "2em" },
    }),
  { name: `StudentModul-StudienVerlauf` }
);

type Pruefungsergebnise = ResultType<GetPruefungsergebniseQuery["pruefungsergebnis"]>;

interface PruefungsVerlaufProps {
  pruefungId: number;
}

const { useCreateServerSideColumns, useMakeServerSideTable, makeSeverSideFilter } = prepareServerSideTable<
  Pruefungsergebnise,
  PruefungsergebniFilterInput,
  PruefungsergebniSortInput,
  SortEnumType
>();

const desc = "DESC" as SortEnumType.Desc;
const asc = "ASC" as SortEnumType.Asc;

const PruefungsErgebnisse: React.FC<PruefungsVerlaufProps> = ({ pruefungId }) => {
  const variables = {
    where: { pruefungId: { eq: pruefungId } },
    order: [{ student: { nachname: asc } }, { pruefungsergebniId: desc }],
  };
  const [queryPre, { data, refetch }] = useGetPruefungsergebniseLazyQuery({
    fetchPolicy: "cache-and-network",
    variables,
  });

  const [createPruefungsergebnis] = useCreatePruefungsergebnisMutation();

  const [ergebnissListe, setErgebnissListe] = useState<Pruefungsergebnise[]>([]);

  const classes = useStyles();
  const PunkteCell = useMakePunkteCell("punkte");
  const NoteCell = useMakeNoteCell();
  const BemerkungCell = useMakeInputCell("bemerkung");
  const StatusCell = useMakeForeignKeySelctor("pruefungStatusId", false);

  const query = (vars: any) => {
    queryPre({ variables: { ...variables, ...vars } });
  };

  const ActionCell = actionsCellFactory({
    onDelete: {
      entity: "pruefungsergebni",
      message: (
        <>
          <Typography variant="h4">{translations.globals.popupMessages.dataloss_2}</Typography>
          <Typography variant="h4">Änderungen werden ggf. zurückgesetzt.</Typography>
        </>
      ),
      afterDelete: () => (refetch ? refetch() : {}),
    },
  });

  const addErgebniss = useCallback(
    async (studentIds: number[]) => {
      const pruefungsergebnis = studentIds.map((studentId) => ({
        studentId: studentId,
        pruefungId,
        pruefungStatusId: 1,
      }));
      const addedPruefungs = await createPruefungsergebnis({
        variables: { pruefungsergebnis },
      });

      setErgebnissListe((list) =>
        list.concat((addedPruefungs.data?.createPruefungsergebnis ?? []) as Pruefungsergebnise[])
      );
    },
    [setErgebnissListe, pruefungId]
  );

  useEffect(() => {
    if (data?.pruefungsergebnis) {
      const nextData = (data?.pruefungsergebnis ?? []).reduce<Pruefungsergebnise[]>((list, row) => {
        const studentExists = ~list.findIndex((ergebniss) => ergebniss.studentId === row.studentId);
        if (!studentExists) return list.concat(row);
        return list;
      }, []);
      setErgebnissListe(nextData);
    }
  }, [data]);

  const { columns, serversideQueryConfig } = useCreateServerSideColumns(
    [
      {
        Header: "Teilnehmer",
        accessor: (row) => `${row.student?.nachname}, ${row.student?.vorname}`,
        id: "Teilnehmer",
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { student: { nachname: { contains: filterValue } } }),
        }),
        maxWidth: 150,
        minWidth: 50,
        width: 60,
      },

      {
        Header: "Punkte",
        accessor: "punkte",
        id: "punkte",
        // remoteOrder: ({ order, sort }) => ({ punkte: sort }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { punkte: { eq: filterValue } }),
        }),
        Cell: PunkteCell,
        maxWidth: 70,
        minWidth: 50,
        width: 60,
      },

      {
        Header: "Note",
        accessor: (row) => row,
        id: "note3",
        // remoteOrder: ({ order, sort }) => ({ note: sort }),
        remoteFilter: makeSeverSideFilter<number>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { note: { eq: filterValue } }),
        }),
        // eslint-disable-next-line react/display-name
        Cell: NoteCell,
        maxWidth: 70,
        minWidth: 50,
        width: 60,
      },

      {
        Header: "Status",
        accessor: (row) => row.pruefungStatus?.pruefungStatusName,
        id: "Status",
        // remoteOrder: ({ order, sort }) => ({ pruefungStatus: { pruefungStatusName: sort } }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) =>
            merge(where, { pruefungStatus: { pruefungStatusName: { eq: filterValue } } }),
        }),
        Cell: StatusCell,
        maxWidth: 70,
        minWidth: 50,
        width: 60,
      },
      {
        Header: "Bemerkung",
        accessor: "bemerkung",
        id: "bemerkung",
        width: 60,
        // remoteOrder: ({ order, sort }) => ({ bemerkung: sort }),
        remoteFilter: makeSeverSideFilter<string>({
          filterPath: ({ filterValue, where, merge }) => merge(where, { bemerkung: { contains: filterValue } }),
        }),
        Cell: BemerkungCell,
      },
      {
        Header: "",
        accessor: "id",
        id: "id",
        // eslint-disable-next-line react/display-name
        Cell: ActionCell,
        width: 25,
        maxWidth: 25,
      },
    ],
    [refetch]
  );

  const { TableComponent: NewTableComponent } = useMakeServerSideTable(
    {
      data: ergebnissListe,
      columns,
      initialState: { pageSize: 25 },
    },
    {
      query,
      variables,
      serversideQueryConfig,
      tableId: "PruefungenPruefungsErgebnisse",
    }
  );

  return (
    <div className={classes.root}>
      <div className={classes.listRoot}>
        <AddStundentsToPruefung
          studentsInPruefung={ergebnissListe.map((ergebniss) => ergebniss.studentId ?? 0)}
          addStundents={addErgebniss}
          pruefungId={pruefungId}
        />
      </div>

      <NewTableComponent tableProps={{ rowsPerPageOptions: [10, 25, 50, 100] }} />
    </div>
  );
};

export default PruefungsErgebnisse;
