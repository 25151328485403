import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Theme } from "@material-ui/core";
import Props from "./index.d";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: "100%",
        width: "100%",
        // margin: "0 auto",
        // padding: "0 1em",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      readonly: {
        height: "100%",
        width: "100%",
        // margin: "0 auto",
        // padding: "0 1em",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pointerEvents: "none",
        cursor: " not-allowed! important",
      },
      children: {
        maxWidth: "2400px",
        width: "100%",
      },
      placeholder: { height: "3em" },
    }),
  { name: "FormWrapper" }
);
interface GFCFieldSet_FormWrapper extends Props {
  children: React.ReactNode;
}

const FieldSetFixedFooter: React.FC<GFCFieldSet_FormWrapper> = (props) => {
  const { children } = props;
  const classes = useStyles();
  //  @ts-ignore
  if (props.hidden) return null;
  if (props.readonly) {
    return (
      <div className={classes.readonly}>
        <div className={classes.children}>{children}</div>
        <div className={classes.placeholder} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.children}>{children}</div>
      <div className={classes.placeholder} />
    </div>
  );
};
export default FieldSetFixedFooter;
