import { EntityInner } from "../configurator/types";

const fieldstoExclude = ["submit", "formErrors", "cancel", "reset", "dirty", "virtualField"];

const checkClientFieldsPre = (clientFields: string[]) => (field: string, path?: string) =>
  clientFields.includes(path ? path : field) ? `${field} @client` : field;

const getFields = ({
  usedFields,
  requiredFields,
  usedMappingInfos,
  clientFields,
  externalFields,
  entity,
}: {
  entity: string;
  usedFields: string[];
  requiredFields: string[];
  usedMappingInfos: EntityInner[];
  clientFields: string[];
  externalFields: string[];
}) => {
  // console.log({
  //   usedFields,
  //   requiredFields,
  //   usedMappingInfos,
  //   clientFields,
  //   externalFields,
  //   entity,
  // });
  const checkClientFields = checkClientFieldsPre(clientFields),
    mappingToCache: string[] = [],
    fields: string[] = usedFields
      .concat(requiredFields)
      .filter((x, i, a) => a.indexOf(x) == i && !externalFields.includes(x))
      .map((field) => {
        const mappingInfoPre = usedMappingInfos.find((mappingInfo) => Object.keys(mappingInfo)[0] === field);
        if (mappingInfoPre) {
          // @ts-ignore
          const mappingInfo = mappingInfoPre[field];

          const fields = Object.entries(mappingInfo.fields).reduce((list: string[], [key, value]) => {
            //   console.log(key);
            if (!Object.values(mappingInfo.mappingIds).includes(key)) {
              if (value !== "nested") {
                return list.concat(checkClientFields(key, `${field}.${key}`));
              }
              //  return list.concat(key);
            }
            //  console.log(list);
            return list;
          }, []);
          const mappingIds = Object.entries(mappingInfo.mappingIds).map(([key, value]) => {
            return `${key}:${value}`;
          });

          Object.keys(mappingInfo.mappings).forEach((mapping) => {
            mappingIds.push(`mapping: ${mapping}{\n\t\t\t\tid\n\t\t\t\ttranslationKey\n\t\t\t}`);
            mappingToCache.push(`${mapping}{\n\t\t\t\tid\n\t\t\t\ttranslationKey\n\t\t\t}`);
          });

          return `${field}{\n\t\t\t${mappingIds.concat(fields).join(",\n\t\t\t")}\n\t\t}`;
        }
        return checkClientFields(field, `${entity}.${field}`);
      });
  return { fields: ["id"].concat(fields), mappingToCache };
};

export default (
  {
    entity,
    usedFields,
    requiredFields,
    usedMappingInfos,

    ...rest
  }: any,
  clientFields: string[],
  externalFields: string[],
  useDotNet2_1: boolean
) => {
  const { fields, mappingToCache } = getFields({
    usedFields: usedFields.filter((field: string) => !fieldstoExclude.includes(field)),
    requiredFields,
    usedMappingInfos,
    clientFields,
    externalFields,
    entity,
  });

  const idName = useDotNet2_1 ? `${entity}Id` : "id";

  const query = `query Raftget${entity}($id: Int!) {\n\tresult : ${entity}(${idName}: $id) {\n\t\t${fields.join(
    // const query = `query get${entity}($id: Int!) {\n\tresult : ${entity}(id: $id) {\n\t\t${fields.join(
    ",\n\t\t"
  )}\n\t}${mappingToCache.join("\n\n")}\n}
       
   `;
  // console.log(query, mappingToCache);

  return query;
};
