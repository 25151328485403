export default {
  save: "Speichern",
  create: "Erstellen",
  add: "Hinzufügen",
  new: "Neu / Neue / Neuen ",
  assign: "Zuordnen",
  change: "Ändern",
  next: "Weiter",
  back: "Zurück",
  continue: "Fortsetzen",
  confirm: "Bestätigen",
  submit: "Absenden",
  apply: "Bewerben",
  send: "Senden",
  yes: "Ja",
  no: "Nein",
  cancel: "Abbrechen",
  OK: "OK",
  done: "Fertig",
  reserve: "Reservieren",
  settings: "Einstellungen",
  delete: "Löschen",
  study: "Studienplatz sichern",
  plan: "planen",
  accept: "planen",
  download: "herunterladen",
};
