import GFCForm_Base from "../../components/formElements/forms/form"
import GFCFieldSet_ConfigNode from "../../components/formElements/fieldSets/configNode"
import GFCFieldSet_DIV from "../../components/formElements/fieldSets/fieldSetDiv"
import GFCFieldSet_FixedFooter from "../../components/formElements/fieldSets/fieldSetFixedFooter"
import GFCFieldSet_FixedFooterView from "../../components/formElements/fieldSets/fieldSetFixedFooterView"
import GFCFieldSet_FormWrapper from "../../components/formElements/fieldSets/fieldSetFormWrapper"
import GFCFieldSet_Horizontal from "../../components/formElements/fieldSets/fieldSetHorizontal"
import GFCFieldSet_HorizontalSimple from "../../components/formElements/fieldSets/fieldSetHorizontalSimple"
import GFCFieldSet_LayoutHorizontal from "../../components/formElements/fieldSets/fieldSetLayoutHorizontal"
import GFCFieldSet_Vertical from "../../components/formElements/fieldSets/fieldSetLayoutVertical"
import GFCFieldSet_NoSideBar from "../../components/formElements/fieldSets/fieldSetNoSidebar"
import GFCFieldSet_NoSideBarWithScroll from "../../components/formElements/fieldSets/fieldSetNoSidebarScroll"
import GFCFieldSet_Stepper from "../../components/formElements/fieldSets/fieldSetStepper"
import GFCFieldSet_VerticalSimple from "../../components/formElements/fieldSets/fieldSetVerticalSimple"
import GFCFieldSet_ResponsiveColumns from "../../components/formElements/fieldSets/fielsSetResponsiveColumns"
import GFCFieldSet_GridContainer from "../../components/formElements/fieldSets/grid/fieldSetGridContainer"
import GFCFieldSet_GridContainerItem from "../../components/formElements/fieldSets/grid/fieldSetGridContainerItem"
import GFCFieldSet_Griditem from "../../components/formElements/fieldSets/grid/fieldSetGridItem"
import GFCField_BooleanToggleButton from "../../components/formElements/fields/booleanToggleButton"
import GFCField_CancelButton from "../../components/formElements/fields/cancelButton"
import GFCField_CheckBoxNumber from "../../components/formElements/fields/checkBox"
import GFCField_CheckBoxBoolean from "../../components/formElements/fields/checkBoxBoolean"
import GFCField_DatePicker from "../../components/formElements/fields/datePicker"
import GFCField_DatePicker2 from "../../components/formElements/fields/datePicker2"
import GFCField_DateTimePicker from "../../components/formElements/fields/DateTimePicker"
import GFCField_DecimalEditor from "../../components/formElements/fields/decimalEditor"
import GFCField_DropDown from "../../components/formElements/fields/dropdown"
import GFCField_EmailWithConfirmation from "../../components/formElements/fields/emailWithConfirmation"
import GFCField_FormErrors from "../../components/formElements/fields/errorDisplay"
import GFCField_FileList from "../../components/formElements/fields/fileUpload/fileList"
import GFCField_FileUpload from "../../components/formElements/fields/fileUpload"
import GFCField_ForeignKeySelector from "../../components/formElements/fields/foreignKeySelector"
import GFCField_ForeignKeySelectorInt from "../../components/formElements/fields/foreignKeySelectorInt"
import GFCField_ForeignKeySelectorIntStudienberater from "../../components/formElements/fields/foreignKeySelectorIntStudienberater"
import GFCField_IntegerSelect from "../../components/formElements/fields/IntegerSelect"
import GFCField_LinkField from "../../components/formElements/fields/linkField"
import GFCField_ModulPruefungTyp from "../../components/formElements/fields/ModulPruefungTyp"
import GFCField_Modulzuordnung from "../../components/formElements/fields/Modulzuordnung"
import GFCField_ModulzuordnungTable from "../../components/formElements/fields/ModulzuordnungTable"
import GFCField_MoodleID from "../../components/formElements/fields/MoodleID"
import GFCField_MoodleModulDisplayStudiengangType from "../../components/formElements/fields/MoodleModulDisplayTextFieldModul"
import GFCField_MoodleModulDisplayZeitraum from "../../components/formElements/fields/MoodleModulDisplayZeitraum"
import GFCField_MoodleModuleInternId from "../../components/formElements/fields/MoodleModulInterndId"
import GFCField_MoodleModulPruefungen from "../../components/formElements/fields/MoodleModulPruefungen"
import GFCField_MoodleModulStudents from "../../components/formElements/fields/MoodleModulStudents"
import GFCField_MoodleModulTermine from "../../components/formElements/fields/MoodleModulTermine"
import GFCField_MoodleModulValueOnPruefung from "../../components/formElements/fields/MoodleModulValueOnPruefung"
import GFCField_MyStudentModul from "../../components/formElements/fields/MyStudent"
import GFCField_PercentEditor from "../../components/formElements/fields/percentEditor"
import GFCField_PruefungenPruefungsErgebnisse from "../../components/formElements/fields/PruefungenPruefungsErgebnisse"
import GFCField_PruefungForPruefungsErgebni from "../../components/formElements/fields/pruefungForPruefungsErgebni"
import GFCField_PruefungsTypOnPruefung from "../../components/formElements/fields/PruefungsTypOnPruefung"
import GFCField_PrufungsFormOnModule from "../../components/formElements/fields/PrufungsFormOnModule"
import GFCField_PrufungsFormOnPruefung from "../../components/formElements/fields/PrufungsFormOnPruefung"
import GFCField_resetButton from "../../components/formElements/fields/resetButton"
import GFCField_Select from "../../components/formElements/fields/selectBox"
import GFCField_SemesterModulZuordnung from "../../components/formElements/fields/SemesterModulZuordnung"
import GFCField_StepperButton from "../../components/formElements/fields/StepperButton"
import GFCField_StepperField from "../../components/formElements/fields/StepperField"
import GFCField_StudentModul from "../../components/formElements/fields/StudentModul"
import GFCField_StudentVerlauf from "../../components/formElements/fields/StudentVerlauf"
import GFCField_Studiengang from "../../components/formElements/fields/studienGangSemesterSelect"
import GFCField_StudienVerlauf from "../../components/formElements/fields/StudienVerlauf"
import GFCField_StudiumArt from "../../components/formElements/fields/StudiumArt"
import GFCField_StudiumStatus from "../../components/formElements/fields/StudiumStatus"
import GFCField_SubmitButton from "../../components/formElements/fields/submitButton"
import GFCField_SubmitButtonMoodle from "../../components/formElements/fields/sumbitButtonMoodle"
import GFCField_Summary from "../../components/formElements/fields/summary"
import GFCField_TabsBar from "../../components/formElements/fields/TabsBar"
import GFCField_TeilnehmerSubmitButtonClose from "../../components/formElements/fields/TeilnehmerSubmitButtonClose"
import GFCField_TextArea from "../../components/formElements/fields/textArea"
import GFCField_TextField from "../../components/formElements/fields/textField"
import GFCField_Title from "../../components/formElements/fields/title"

export default [
  {
    Component: GFCForm_Base,
    uiType: "GFCForm_Base",
  },
  {
    Component: GFCFieldSet_ConfigNode,
    uiType: "GFCFieldSet_ConfigNode",
  },
  {
    Component: GFCFieldSet_DIV,
    uiType: "GFCFieldSet_DIV",
  },
  {
    Component: GFCFieldSet_FixedFooter,
    uiType: "GFCFieldSet_FixedFooter",
  },
  {
    Component: GFCFieldSet_FixedFooterView,
    uiType: "GFCFieldSet_FixedFooterView",
  },
  {
    Component: GFCFieldSet_FormWrapper,
    uiType: "GFCFieldSet_FormWrapper",
  },
  {
    Component: GFCFieldSet_Horizontal,
    uiType: "GFCFieldSet_Horizontal",
  },
  {
    Component: GFCFieldSet_HorizontalSimple,
    uiType: "GFCFieldSet_HorizontalSimple",
  },
  {
    Component: GFCFieldSet_LayoutHorizontal,
    uiType: "GFCFieldSet_LayoutHorizontal",
  },
  {
    Component: GFCFieldSet_Vertical,
    uiType: "GFCFieldSet_Vertical",
  },
  {
    Component: GFCFieldSet_NoSideBar,
    uiType: "GFCFieldSet_NoSideBar",
  },
  {
    Component: GFCFieldSet_NoSideBarWithScroll,
    uiType: "GFCFieldSet_NoSideBarWithScroll",
  },
  {
    Component: GFCFieldSet_Stepper,
    uiType: "GFCFieldSet_Stepper",
  },
  {
    Component: GFCFieldSet_VerticalSimple,
    uiType: "GFCFieldSet_VerticalSimple",
  },
  {
    Component: GFCFieldSet_ResponsiveColumns,
    uiType: "GFCFieldSet_ResponsiveColumns",
  },
  {
    Component: GFCFieldSet_GridContainer,
    uiType: "GFCFieldSet_GridContainer",
  },
  {
    Component: GFCFieldSet_GridContainerItem,
    uiType: "GFCFieldSet_GridContainerItem",
  },
  {
    Component: GFCFieldSet_Griditem,
    uiType: "GFCFieldSet_Griditem",
  },
  {
    Component: GFCField_BooleanToggleButton,
    uiType: "GFCField_BooleanToggleButton",
  },
  {
    Component: GFCField_CancelButton,
    uiType: "GFCField_CancelButton",
  },
  {
    Component: GFCField_CheckBoxNumber,
    uiType: "GFCField_CheckBoxNumber",
  },
  {
    Component: GFCField_CheckBoxBoolean,
    uiType: "GFCField_CheckBoxBoolean",
  },
  {
    Component: GFCField_DatePicker,
    uiType: "GFCField_DatePicker",
  },
  {
    Component: GFCField_DatePicker2,
    uiType: "GFCField_DatePicker2",
  },
  {
    Component: GFCField_DateTimePicker,
    uiType: "GFCField_DateTimePicker",
  },
  {
    Component: GFCField_DecimalEditor,
    uiType: "GFCField_DecimalEditor",
  },
  {
    Component: GFCField_DropDown,
    uiType: "GFCField_DropDown",
  },
  {
    Component: GFCField_EmailWithConfirmation,
    uiType: "GFCField_EmailWithConfirmation",
  },
  {
    Component: GFCField_FormErrors,
    uiType: "GFCField_FormErrors",
  },
  {
    Component: GFCField_FileList,
    uiType: "GFCField_FileList",
  },
  {
    Component: GFCField_FileUpload,
    uiType: "GFCField_FileUpload",
  },
  {
    Component: GFCField_ForeignKeySelector,
    uiType: "GFCField_ForeignKeySelector",
  },
  {
    Component: GFCField_ForeignKeySelectorInt,
    uiType: "GFCField_ForeignKeySelectorInt",
  },
  {
    Component: GFCField_ForeignKeySelectorIntStudienberater,
    uiType: "GFCField_ForeignKeySelectorIntStudienberater",
  },
  {
    Component: GFCField_IntegerSelect,
    uiType: "GFCField_IntegerSelect",
  },
  {
    Component: GFCField_LinkField,
    uiType: "GFCField_LinkField",
  },
  {
    Component: GFCField_ModulPruefungTyp,
    uiType: "GFCField_ModulPruefungTyp",
  },
  {
    Component: GFCField_Modulzuordnung,
    uiType: "GFCField_Modulzuordnung",
  },
  {
    Component: GFCField_ModulzuordnungTable,
    uiType: "GFCField_ModulzuordnungTable",
  },
  {
    Component: GFCField_MoodleID,
    uiType: "GFCField_MoodleID",
  },
  {
    Component: GFCField_MoodleModulDisplayStudiengangType,
    uiType: "GFCField_MoodleModulDisplayStudiengangType",
  },
  {
    Component: GFCField_MoodleModulDisplayZeitraum,
    uiType: "GFCField_MoodleModulDisplayZeitraum",
  },
  {
    Component: GFCField_MoodleModuleInternId,
    uiType: "GFCField_MoodleModuleInternId",
  },
  {
    Component: GFCField_MoodleModulPruefungen,
    uiType: "GFCField_MoodleModulPruefungen",
  },
  {
    Component: GFCField_MoodleModulStudents,
    uiType: "GFCField_MoodleModulStudents",
  },
  {
    Component: GFCField_MoodleModulTermine,
    uiType: "GFCField_MoodleModulTermine",
  },
  {
    Component: GFCField_MoodleModulValueOnPruefung,
    uiType: "GFCField_MoodleModulValueOnPruefung",
  },
  {
    Component: GFCField_MyStudentModul,
    uiType: "GFCField_MyStudentModul",
  },
  {
    Component: GFCField_PercentEditor,
    uiType: "GFCField_PercentEditor",
  },
  {
    Component: GFCField_PruefungenPruefungsErgebnisse,
    uiType: "GFCField_PruefungenPruefungsErgebnisse",
  },
  {
    Component: GFCField_PruefungForPruefungsErgebni,
    uiType: "GFCField_PruefungForPruefungsErgebni",
  },
  {
    Component: GFCField_PruefungsTypOnPruefung,
    uiType: "GFCField_PruefungsTypOnPruefung",
  },
  {
    Component: GFCField_PrufungsFormOnModule,
    uiType: "GFCField_PrufungsFormOnModule",
  },
  {
    Component: GFCField_PrufungsFormOnPruefung,
    uiType: "GFCField_PrufungsFormOnPruefung",
  },
  {
    Component: GFCField_resetButton,
    uiType: "GFCField_resetButton",
  },
  {
    Component: GFCField_Select,
    uiType: "GFCField_Select",
  },
  {
    Component: GFCField_SemesterModulZuordnung,
    uiType: "GFCField_SemesterModulZuordnung",
  },
  {
    Component: GFCField_StepperButton,
    uiType: "GFCField_StepperButton",
  },
  {
    Component: GFCField_StepperField,
    uiType: "GFCField_StepperField",
  },
  {
    Component: GFCField_StudentModul,
    uiType: "GFCField_StudentModul",
  },
  {
    Component: GFCField_StudentVerlauf,
    uiType: "GFCField_StudentVerlauf",
  },
  {
    Component: GFCField_Studiengang,
    uiType: "GFCField_Studiengang",
  },
  {
    Component: GFCField_StudienVerlauf,
    uiType: "GFCField_StudienVerlauf",
  },
  {
    Component: GFCField_StudiumArt,
    uiType: "GFCField_StudiumArt",
  },
  {
    Component: GFCField_StudiumStatus,
    uiType: "GFCField_StudiumStatus",
  },
  {
    Component: GFCField_SubmitButton,
    uiType: "GFCField_SubmitButton",
  },
  {
    Component: GFCField_SubmitButtonMoodle,
    uiType: "GFCField_SubmitButtonMoodle",
  },
  {
    Component: GFCField_Summary,
    uiType: "GFCField_Summary",
  },
  {
    Component: GFCField_TabsBar,
    uiType: "GFCField_TabsBar",
  },
  {
    Component: GFCField_TeilnehmerSubmitButtonClose,
    uiType: "GFCField_TeilnehmerSubmitButtonClose",
  },
  {
    Component: GFCField_TextArea,
    uiType: "GFCField_TextArea",
  },
  {
    Component: GFCField_TextField,
    uiType: "GFCField_TextField",
  },
  {
    Component: GFCField_Title,
    uiType: "GFCField_Title",
  },
]
